// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import UserProjectsList from '../view/UserProjectsList';

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data');
  return response.data;
});
export const setUserProfile = createAsyncThunk(
  'appUsers/setUserProfile',
  async (data) => {
    console.log('setUserProfileeeee dataaaaaaaaaaaaaa', data);
    return data;
  }
);
export const setOrderDetails = createAsyncThunk(
  'appUsers/setOrderDetails',
  async (data) => {
    return data;
  }
);
export const getAllTasks = createAsyncThunk(
  'appUsers/getAllTasks',
  async ({
    keyword,
    limit,
    offset,
    sortColumn,
    sort,
    manager_id,
    status,
    assigned_to,
  }) => {
    // Assuming both sortColumn and sort are needed for the 'orderBy[]' key
    const data = {
      keyword,
      limit,
      offset,
      'orderBy[]': [sortColumn, sort], // Combine both in an array
      manager_id,
      status,
      assigned_to,
    };

    // Filter out empty parameters
    const filteredParams = Object.entries(data).reduce((acc, [key, value]) => {
      // Handle array separately to filter out empty strings inside the array
      if (Array.isArray(value)) {
        const nonEmpty = value.filter(
          (v) => v !== null && v !== undefined && v !== ''
        );
        if (nonEmpty.length) acc[key] = nonEmpty;
      }
      // Check if the value is not null, not undefined, and not an empty string
      else if (value !== null && value !== undefined && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});

    const response = await axios.get(
      'https://api-v2-test.laffahrestaurants.com/api/tasks',
      {
        params: filteredParams,
      }
    );
    return response.data;
  }
);
export const getStatuses = createAsyncThunk(
  'appUsers/getStatuses',
  async () => {
    const response = await axios.get(
      'https://api-v2-test.laffahrestaurants.com/api/tasks/statuses'
    );
    return response.data.data;
  }
);
export const getManagers = createAsyncThunk(
  'appUsers/getManagers',
  async () => {
    const response = await axios.get(
      'https://api-v2-test.laffahrestaurants.com/public/api/users?role=customer_care_manager&offset=0&limit=120'
    );
    return response.data.data;
  }
);
export const getAgents = createAsyncThunk('appUsers/getAgents', async () => {
  const response = await axios.get(
    'https://api-v2-test.laffahrestaurants.com/public/api/users?role=customer_care&offset=0&limit=120'
  );
  return response.data.data;
});
export const getTask = createAsyncThunk('appUsers/getTask', async (id) => {
  const response = await axios.get(
    `https://api-v2-test.laffahrestaurants.com/api/tasks/${id}`
  );
  return response.data.data;
});
export const getReplies = createAsyncThunk(
  'appUsers/getReplies',
  async (id) => {
    const response = await axios.get(
      `https://api-v2-test.laffahrestaurants.com/api/tasks/replies?task_id=${id}`
    );
    return response.data.data;
  }
);
export const update_Task = createAsyncThunk(
  'appUsers/update_Task',
  async (data) => {
    const response = await axios.put(
      `https://api-v2-test.laffahrestaurants.com/api/tasks/${data.taskID}`,
      {
        assigned_to: data.assigned_to,
      }
    );
    return response.data.data;
  }
);
export const deleteTask = createAsyncThunk(
  'appUsers/deleteTask',
  async (id) => {
    const response = await axios.delete(
      `https://api-v2-test.laffahrestaurants.com/api/tasks/${id}`
    );
    return response.data.data;
  }
);

export const getData = createAsyncThunk('appUsers/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params);
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total,
  };
});

export const getUser = createAsyncThunk('appUsers/getUser', async (id) => {
  const response = await axios.get('/api/users/user', { id: 2 });
  return response.data.user;
});
export const getUserProfile = createAsyncThunk(
  'appUsers/getUserProfile',
  async (id) => {
    const response = await axios.get(`
  https://api-v2-test.laffahrestaurants.com/public/api/users/${id}`);
    return response.data.data;
  }
);
export const getOrder = createAsyncThunk('appUsers/getOrder', async (id) => {
  const response = await axios.get(`
    https://api-v2-test.laffahrestaurants.com/public/api/orders/${id}`);
  return response.data.data;
});
export const getOrderStatuses = createAsyncThunk(
  'appUsers/getOrderStatuses',
  async () => {
    const response = await axios.get(`
  https://api-v2-test.laffahrestaurants.com/public/api/orders/getstatuses
  `);
    console.log(response.data.data);
    return response.data.data;
  }
);

export const addTask = createAsyncThunk(
  'appUsers/addTask',
  async (task, { dispatch, getState }) => {
    await axios.post(
      'https://api-v2-test.laffahrestaurants.com/api/tasks',
      task
    );
    // await dispatch(getData(getState().tasks.params));
    // await dispatch(getAllData());
    return task;
  }
);

export const deleteUser = createAsyncThunk(
  'appUsers/deleteUser',
  async (id, { dispatch, getState }) => {
    await axios.delete('/apps/users/delete', { id });
    await dispatch(getData(getState().users.params));
    await dispatch(getAllData());
    return id;
  }
);

export const appUsersSlice = createSlice({
  name: 'task',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    allTasks: [],
    statuses: [],
    managers: [],
    agents: [],
    replies: [],
    task: {},
    userProfile: {},
    orderDetails: {},
    task: {},
    updateTask: null,
    deleteTask: null,
    selectedUser: null,
    user: null,
    order: null,
    addTask: null,
    addTask: null,
    orderStatuses: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload;
      })
      // Task List

      .addCase(getAllTasks.pending, (state, action) => {})
      .addCase(getAllTasks.fulfilled, (state, action) => {
        state.allTasks = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(getAllTasks.rejected, (state, action) => {})
      //  userProfile: {},
      .addCase(setUserProfile.fulfilled, (state, action) => {
        console.log(
          'setUserProfileeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
          state.userProfile,
          action.payload
        );
        state.userProfile = action.payload;
      })
      //  orderDetails: {},
      .addCase(setOrderDetails.rejected, (state, action) => {
        state.orderDetails = action.payload;
        console.log(
          'setOrderDetailseeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
          state.orderDetails,
          action.payload
        );
        state.orderDetails = action.payload;
      })
      // Statuses

      .addCase(getStatuses.pending, (state, action) => {})
      .addCase(getStatuses.fulfilled, (state, action) => {
        state.statuses = action.payload;
      })
      .addCase(getStatuses.rejected, (state, action) => {})
      // Statuses

      .addCase(getManagers.pending, (state, action) => {})
      .addCase(getManagers.fulfilled, (state, action) => {
        state.managers = action.payload;
      })
      .addCase(getManagers.rejected, (state, action) => {})
      // getAgents

      .addCase(getAgents.pending, (state, action) => {})
      .addCase(getAgents.fulfilled, (state, action) => {
        state.agents = action.payload;
      })
      .addCase(getAgents.rejected, (state, action) => {})

      // updateTask
      .addCase(update_Task.pending, (state, action) => {})
      .addCase(update_Task.fulfilled, (state, action) => {
        state.updateTask = action.payload;
      })
      .addCase(update_Task.rejected, (state, action) => {})

      // deleteTask
      .addCase(deleteTask.pending, (state, action) => {})
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.deleteTask = action.payload;
      })
      .addCase(deleteTask.rejected, (state, action) => {})
      // Single task
      .addCase(getTask.pending, (state, action) => {})
      .addCase(getTask.fulfilled, (state, action) => {
        console.log(state.task);
        state.task = action.payload;
        console.log(state.task);
      })
      .addCase(getTask.rejected, (state, action) => {})
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        console.log('fulfilledddddddddddddddddddddddddddddddddddddddd');
        state.user = action.payload;
      })

      .addCase(getUserProfile.rejected, (state, action) => {
        console.log('rejecteddddddddddddddddddddddddddddddddddddddd');
        console.log(state.user, state.userProfile);
        state.user = state.userProfile;
        console.log(
          'rejecteddddddddddddddddddddddddddddddddddddddd',
          state.user
        );
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.order = action.payload;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.order = state.orderDetails;
      })
      .addCase(getOrderStatuses.fulfilled, (state, action) => {
        state.orderStatuses = action.payload;
      })
      .addCase(addTask.fulfilled, (state, action) => {
        state.addTask = action.payload;
      })
      .addCase(getReplies.fulfilled, (state, action) => {
        state.replies = action.payload;
      });
  },
});

export default appUsersSlice.reducer;
